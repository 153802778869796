export default {
    quote: {
        topic: {},
        title: `"Now that we have monday.com Work OS as the backbone of our organization I feel I have an overview I can trust."`,
        body: null
    },
    author: "Charlie MacGregor, Founder & CEO",
    company: {
        title: "The Student Hotel"
    }
};

import { useCallback, useEffect, useState } from "react";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const useSlidesToShow = ()=>{
    const [slidesToShow, setSlidesToShow] = useState(1);
    const getSlidesToShow = useCallback(()=>{
        const screenWidth = window.innerWidth;
        if (screenWidth < MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH) return 1;
        const slidesContainerWidth = screenWidth - (screenWidth - MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH) / 2;
        const slidesInScreen = slidesContainerWidth / MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH;
        setSlidesToShow(slidesInScreen);
    }, []);
    useEffect(()=>{
        getSlidesToShow();
        window.addEventListener("resize", getSlidesToShow);
        return ()=>{
            window.removeEventListener("resize", getSlidesToShow);
        };
    }, [
        getSlidesToShow
    ]);
    return slidesToShow;
};

import styled from "@emotion/styled";
import Colors from "styles/colors";
import { MID_HEADER_DESKTOP_MENU } from "constants/vp-sizes";
export const StyledStatisticComponent = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  .vertical-line {
    height: 100%;
    width: 4px;
    border-radius: 6px;
    background-color: ${({ lineColor })=>Colors[lineColor]};
    margin-right: ${({ lineMargin })=>lineMargin || "24px"};
  }

  @media (max-width: ${MID_HEADER_DESKTOP_MENU}px) {
    .full-paragraph {
      .title-wrapper {
        margin-bottom: 12px;

        .core-title-container .core-title {
          font-size: 1.75rem;
          line-height: 40px;
        }
      }

      .paragraph-body-wrapper {
        .paragraph-wrapper .paragraph-body {
          font-size: 1rem;
          line-height: 24px;
        }
      }
    }
  }
`;

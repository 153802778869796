import styled from "@emotion/styled";
import { LAYER_ONE, LAYER_TWO } from "constants/z-index";
import { FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MAX_DESKTOP_CONTENT_WIDTH } from "constants/vp-sizes";
const TESTIMONIAL_CONTENT_GAP = "32px";
export const StyledTabContentComponent = styled.div`
  position: relative;

  .asset-inner {
    z-index: ${LAYER_ONE};

    video,img {
      border-radius: 20px;
    }
  }

  .content-wrapper {
    padding: calc(max(100vw / ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH} * 80, 12px));
    z-index: ${LAYER_TWO};
    position: absolute;
    display: flex;
    justify-content: space-between;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .testimonial-wrapper {
      display: flex;
      width: fit-content;
      flex-direction: column;
      gap: ${TESTIMONIAL_CONTENT_GAP};

      .testimonial {
        padding: 0;
        max-width: ${({ testimonialMaxWidth })=>testimonialMaxWidth || "100%"};
        width: fit-content;
        justify-content: flex-start;

        .content {
          width: fit-content;
          justify-content: flex-end;
          gap: ${TESTIMONIAL_CONTENT_GAP};

          .quote {
            font-size: 1.25rem;
            line-height: 32px;
            margin-bottom: 0;
          }

          .author {
            gap: 16px;

            .asset-inner.author-image {
              width: 40px;
            }
          }

          @media (max-width: ${MAX_DESKTOP_CONTENT_WIDTH}px) {
            .quote {
              font-size: 1.125rem;
            }
          }
        }
      }
    }

    .stats-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 32px;

      @media (max-width: ${MAX_DESKTOP_CONTENT_WIDTH}px) {
        gap: 16px;
      }
    }
  }
`;

import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import Constants from "segments/desktop/header/layouts/components/basic-header/constants";
export const StyledCarouselTabsComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;
  min-width: 990px;

  .carousel-tabs-container {
    display: flex;
    flex-direction: column;
    gap: ${({ containerGap })=>containerGap || "32px"};

    .carousel {
      .slick-track {
        display: flex;

        .slick-slide {
          border-radius: 20px;
          transition: opacity 0.5s;

          &.slick-current {
            padding: 0 ${Constants.HEADER_CONTENT_HORIZONTAL_PADDING}px;
          }

          &:not(.slick-current) {
            padding-right: ${Constants.HEADER_CONTENT_HORIZONTAL_PADDING * 2}px;
          }

          &.slick-current {
            opacity: 1;
          }

          &:not(.slick-current) {
            opacity: 0.5;
            cursor: pointer;
          }
        }
      }

      @media (min-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) {
        margin-right: calc((100vw - ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) / -2);
      }
    }
  }
`;

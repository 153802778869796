import styled from "@emotion/styled";
import Colors from "styles/colors";
import { WHITE_COLOR_NAME } from "styles/color-consts";
import { MD } from "constants/vp-sizes";
const mdScreenSize = `${MD}px`;
export const StylesTestimonial = styled.div`
  display: flex;
  padding-top: 64px;
  padding-bottom: 64px;
  justify-content: center;
  height: 100%;
  width: 100%;

  .content {
    display: flex;
    gap: 45px;
    width: 65%;
    justify-content: center;
    flex-direction: column;

    .quote {
      font-size: 1.875rem;
      margin-bottom: 50px;
    }

    .author {
      display: flex;
      align-items: center;
      gap: 25px;
      font-size: 1rem;

      .author-image {
        width: 60px;
      }

      .company {
        font-weight: 700;
        line-height: 24px;
        font-size: 1rem;
      }
    }
  }

  @media (max-width: ${mdScreenSize}) {
    padding: 32px 16px;

    .content {
      width: 100%;
      gap: 24px;
      line-height: 1.5rem;

      .author-details-container {
        .author-name {
          font-size: 0.75rem;
        }

        .company {
          font-size: 0.75rem;
        }
      }

      .quote {
        margin-bottom: 0;
      }
    }
  }

  &.white {
    color: ${Colors[WHITE_COLOR_NAME]};
  }

  &.black {
    color: #000;
  }
`;

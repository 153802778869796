import styled from "@emotion/styled";
import Colors from "styles/colors";
import Constants from "segments/desktop/header/layouts/components/basic-header/constants";
export const StyledTabsLabelsComponent = styled.div`
  display: flex;
  gap: 24px;
  cursor: pointer;
  padding: 0 ${Constants.HEADER_CONTENT_HORIZONTAL_PADDING}px;

  .label {
    flex: 1;
    display: flex;
    flex-direction: column;

    img {
      height: ${({ tabLabelHeight })=>tabLabelHeight || "32px"};
      object-fit: contain;
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;
    }

    &.selected {
      &.active {
        img {
          opacity: 1;
        }

        .underline-placeholder::after {
          animation: fill-progress-bar linear forwards;
          animation-duration: ${({ transitionTime })=>`${transitionTime}ms`};
        }
      }

      &:not(.active) {
        .underline-placeholder::after {
          width: 100%;
        }
      }
    }

    .underline-placeholder {
      margin-top: 24px;
      background-color: rgba(255, 255, 255, 0.2);
      position: relative;
      height: 6px;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 40px;

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 100%;
        background-color: ${({ progressbarColor })=>Colors[progressbarColor] || Colors.White};
        left: 0;
        right: 0;
        border-radius: 40px;
      }
    }

    @keyframes fill-progress-bar {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }
  }
`;
